/* NewCustomerPage.module.css */

.container {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
}

.title {
    font-size: 2em;
    margin-bottom: 20px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.inputField {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.submitButton {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
}

.submitButton:hover {
    background-color: #0056b3;
}
