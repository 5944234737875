.vendor-selection-container {
    display: flex;
    flex-direction: column;
}

.vendor-selection-content {
    overflow: auto; /* Allows the content to scroll independently of the nav bar */
    flex-grow: 1; /* Ensures the content section fills the space below the nav bar */
}

.vendor-select {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 16px;
}

.products-table {
    width: 100%;
    border-collapse: collapse;
}

.products-table th, .products-table td {
    text-align: left;
    padding: 8px;
    border: 1px solid #ddd;
}

.products-table th {
    background-color: #f2f2f2;
}

.quantity-input {
    width: 60px;
    padding: 5px;
    margin-right: 10px;
}

button {
    background-color: #4CAF50; /* Green */
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #45a049;
}
