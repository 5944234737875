.container {
    padding: 40px;
    background-color: #f3f4f6; /* Light gray background */
    min-height: 100vh; /* Full height view */
    color: #333; /* Dark text for readability */
    font-family: 'Arial', sans-serif; /* Simple, readable font */
}

.header {
    text-align: center;
    color: #4a4a4a;
    margin-bottom: 30px;
}

.updatesList {
    list-style: none; /* Remove default list styling */
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive grid layout */
    gap: 20px; /* Space between grid items */
    font-size: 18px; /* Slightly larger font size for readability */
}

.updatesList li {
    background-color: #fff; /* White background for each list item */
    padding: 20px;
    border-radius: 10px; /* Rounded corners for a softer look */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    transition: transform 0.3s ease; /* Smooth transition for hover effect */
}

.updatesList li:hover {
    transform: translateY(-5px); /* Slight lift effect on hover */
}
.buttoncontainer{
    display: flex;
    align-items: center;
    justify-content: center;
}
.button {
    padding: 15px 25px;
    font-size: 16px;
    border-radius: 10px;
    background-color: #DAA520;
    color: white;
    border: none;
    cursor: pointer;
    margin: 10px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}
